// This is a 1-to-1 copy of "modules/checkout/composables/useShipping/index.ts"
// with support for "customQuery" added to the "save" function

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import type { ShippingCartAddress, CartItemPrices, Maybe } from '~/modules/GraphQL/types';
import type {
  UseShippingErrors,
  UseShippingInterface,
  UseShippingLoadParams,
  UseShippingSaveParams,
} from './useShipping';
import setSimpleShippingAddressesOnCart from '~/diptyqueTheme/customQueries/magento/setSimpleShippingAddressesOnCartMutation';
import { useApi } from '~/composables';

export interface CartShippingInterface {
  /** The unique ID that identifies the customer's cart */
  prices?: Maybe<CartItemPrices>;
  shipping_addresses: Array<Maybe<ShippingCartAddress>>;
}

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */
export function useShipping(): UseShippingInterface {
  const loading = ref(false);
  const error = ref<UseShippingErrors>({ load: null, save: null });
  const { cart, load: loadCart, mergeCartItems } = useCart();
  const { app } = useContext();
  const { mutate } = useApi();

  const load = async (params: UseShippingLoadParams = {}): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.load');
    let shippingInfo : ShippingCartAddress | null = null;

    try {
      loading.value = true;
      if (cart?.value?.shipping_addresses.length === 0) {
        await loadCart(params);
      }

      [shippingInfo] = cart?.value?.shipping_addresses || [];
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShipping/load', err);
    } finally {
      loading.value = false;
    }
    return shippingInfo;
  };

  const save = async ({ shippingDetails, customQuery = null }: UseShippingSaveParams): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.save');
    let shippingInfo : ShippingCartAddress | null = null;

    try {
      loading.value = true;

      const { id } = cart.value;

      const {
        apartment,
        neighborhood,
        extra,
        customerAddressId,
        storeAddress,
        __typename,
        vat_id,
        suffix,
        prefix,
        id: addressId,
        address_book_type,
        extension_attributes,
        default_shipping,
        default_billing,
        region,
        ...address
      } = shippingDetails;

      const shippingData = customerAddressId && !storeAddress
        ? { customer_address_id: customerAddressId }
        : {
          address: {
            ...address,
            street: [address.street, apartment, neighborhood, extra].filter(Boolean),
          },
        };

      const shippingAddressInput = {
        cart_id: id,
        shipping_addresses: [
          {
            ...shippingData,
          },
        ],
      };

      const { data } = await app.$vsf.$magento.api.setShippingAddressesOnCart(shippingAddressInput, customQuery);

      // Potential fix for the DPTQ-3795 ClickAndCollect floating issue
      removeClickAndCollectIfEmptyBoutiqueList(data);

      data?.setShippingAddressesOnCart?.cart && mergeCartItems(data?.setShippingAddressesOnCart?.cart);

      Logger.debug('[Result]:', { data });

      if (data?.setShippingAddressesOnCart) {
        [shippingInfo] = data?.setShippingAddressesOnCart?.cart?.shipping_addresses;
      }

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo ?? {} as ShippingCartAddress;
  };

  const removeClickAndCollectIfEmptyBoutiqueList = (data) => {
    const clickAndCollectCode = 'expressclickandcollect';
    const availableShippingMethods = data?.setShippingAddressesOnCart?.cart?.shipping_addresses[0]?.available_shipping_methods || [];
    const clickAndCollectMethod = availableShippingMethods.find((method) => method.method_code === clickAndCollectCode);
    const clickAndCollectData = clickAndCollectMethod?.additional_data ? JSON.parse(clickAndCollectMethod.additional_data) : null;
    const hasBoutiquesInsideList = clickAndCollectData ? Object.keys(clickAndCollectData?.boutique_list || {}).length : null;

    // This logs group can be removed as soon there will be a solution for DPTQ-3795
    console.groupCollapsed('>> ClickAndCollect Additional Info');
    console.log('CnC is present in available shipping methods: ' + (clickAndCollectMethod ? 'true' : 'false'));
    console.log('CnC method has additional_data key: ' + (clickAndCollectMethod?.additional_data ? 'true' : 'false'));
    console.log('CnC additional_data has boutique_list key: ' + (clickAndCollectData?.boutique_list ? 'true' : 'false'));
    console.log('CnC boutique_list has boutiques inside array: ' + (hasBoutiquesInsideList ? 'true' : 'false'));
    console.groupEnd();

    if (clickAndCollectMethod && !hasBoutiquesInsideList) {
      console.warn('ATTENTION: ClickAndCollect was removed from available shipping methods as there were no boutiques. Details in DPTQ-3795');
      data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods = availableShippingMethods.filter(
        (method) => method.method_code !== clickAndCollectCode
      );
    }
  };

  const getAvailableShippingMethods = async ({ shippingDetails }: UseShippingSaveParams): Promise<CartShippingInterface | null> => {
    Logger.debug('useShipping.getAvailableShippingMethods');
    let shippingInfo: CartShippingInterface | null = null;

    try {
      loading.value = true;

      const { id } = cart.value;

      const { apartment, neighborhood, extra, customerAddressId, ...address } = shippingDetails;

      const shippingData = customerAddressId
        ? { customer_address_id: customerAddressId }
        : {
            address: {
              ...address,
              street: [address.street, apartment, neighborhood, extra].filter(Boolean)
            }
          };

      const { data } = await mutate(setSimpleShippingAddressesOnCart, {
        input: {
          cart_id: id,
          shipping_addresses: [
            {
              ...shippingData
            }
          ]
        }
      });

      Logger.debug('[Result]:', { data });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (data?.setSimpleShippingAddressesOnCart) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        shippingInfo = data?.setSimpleShippingAddressesOnCart?.cart;
      }

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/getAvailableShippingMethods', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo ?? ({} as CartShippingInterface);
  };

  return {
    load,
    save,
    getAvailableShippingMethods,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from '~/modules/checkout/composables/useShipping';
export default useShipping;
