import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';

export const updateItemQtyCommand = {
  execute: async (context: VsfContext, { currentCart, product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_id: product.id,
          quantity
        }
      ]
    };

    const { data, errors } = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

    Logger.debug('[Result]:', { data });

    if (errors?.length) {
      return errors;
    }

    return data.updateCartItems.cart as unknown as Cart;
  }
};
