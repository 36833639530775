export default `
  query getProductUrlBySku($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        url_rewrites {
          url
        }
      }
    }
  }
`;
