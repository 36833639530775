type LocalStorageItem = {
  _value: string;
  _until: number;
  _guard: string;
};

type LocalStorageItemConfig = {
  eternal?: boolean;
  seconds?: number;
};

// GuardWord is saved as part of a value while each ls.setItem and that is checked each ls.getItem()
// If saved localStorage item's _guard is not equal to env guardWord, value will be returned as undefined
// what can be used to reset all user's localStorage values by changing the env guardWord
const guardWord = process.env.LOCAL_STORAGE_GUARDWORD;

const defaults = {
  // eslint-disable-next-line no-magic-numbers
  seconds: 60 * 60 * 24 * 7 // 7 days in seconds
};

export const lsGet = (key: string): any => {
  if (!process.client || !window || !window.localStorage) return undefined;

  const now = new Date().getTime();
  const item = window.localStorage.getItem(key);
  if (!item) return undefined;

  try {
    const { _value, _until, _guard } = JSON.parse(item) as LocalStorageItem;
    if (_until < now || _guard !== guardWord) return undefined;
    return _value;
  } catch (err) {
    return undefined;
  }
};

export const lsSet = (key: string, value: any, config: LocalStorageItemConfig = {}): boolean => {
  if (!process.client || !window || !window.localStorage) return false;
  if (!config.seconds) config.seconds = defaults.seconds;

  const item = {
    _guard: guardWord,
    // eslint-disable-next-line no-magic-numbers
    _until: config.eternal ? undefined : new Date().getTime() + config.seconds * 1000,
    _value: value
  };

  window.localStorage.setItem(key, JSON.stringify(item));
  return true;
};

export const lsRemove = (key: string): boolean => {
  if (!process.client || !window || !window.localStorage) return false;
  window.localStorage.removeItem(key);
};
