import { computed } from '@nuxtjs/composition-api';
import { sha256 } from 'js-sha256';
import userGetters from '~/modules/customer/getters/userGetters';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useMagentoConfiguration } from '~/composables';
import {
  LOGGED_IN,
  LOGGED_OUT,
  NO_ERROR,
  ECOMMERCE_EVENT,
  EVENT_SELECT_ITEM,
  EVENT_VIEW_ITEM,
  EVENT_VIEW_ITEM_LIST,
  EVENT_ADD_TO_CART,
  EVENT_REMOVE_FROM_CART,
  EVENT_SHOW_CARTPAGE,
  EVENT_BEGIN_CHECKOUT,
  EVENT_ADD_DELIVERY_ADDRESS,
  EVENT_ADD_SHIPPING_INFO,
  EVENT_ADD_PAYMENT_INFO,
  EVENT_PURCHASE
} from './consts';
import {
  formatEventPrice,
  getPageType,
  getSelectedItem,
  getItems,
  getCartItems,
  getExtraServices,
  getNewsletterSubscriptionStatus,
  getPaymentMethod,
  getCartSamples,
  getOrderSamples,
  getOrderItems,
  getOrderExtraServices
} from './helpers';
import { UseGoogleTagManagerInterface, LoginStatus } from './types';

/**
 * Allows getting GTM details for various ecommerce events.
 *
 * See the {@link UseGoogleTagManagerInterface} for a list of methods and values available in this composable.
 */
export function useGoogleTagManager(): UseGoogleTagManagerInterface {
  const { selectedStore, selectedCurrency } = useMagentoConfiguration();
  const currency = computed(() => selectedCurrency.value ?? 'EUR');
  const storeData = computed(() => selectedStore.value?.split('_') ?? []);
  const EVENT_TIMEOUT = 300;

  const getPageviewDetails = (user, isAuthenticated, to, error) => {
    const isSubscribedToNewsletter = getNewsletterSubscriptionStatus(user);
    const email = userGetters.getEmailAddress(user);

    const userId = email ? sha256(email) : 'n/a';
    return {
      event: 'pageview',
      language: storeData.value[0],
      country: storeData.value[1],
      currency: currency.value,
      page_type: getPageType(to),
      page_url: `${to.fullPath}`,
      error: error ?? NO_ERROR,
      login_status: isAuthenticated ? LOGGED_IN : (LOGGED_OUT as LoginStatus),
      user_id: userId,
      newsletter_subscription_status: isSubscribedToNewsletter
    };
  };

  const getSelectItemDetails = (product, index, listId) => {
    return {
      event: EVENT_SELECT_ITEM,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        items: getSelectedItem(product, index, listId)
      }
    };
  };

  const getViewItemDetails = (products, listId) => {
    return {
      event: EVENT_VIEW_ITEM,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        items: getItems({ products, listId })
      }
    };
  };

  const getViewItemListDetails = (products, listId) => {
    return {
      event: EVENT_VIEW_ITEM_LIST,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        items: getItems({ products, quantity: 1, listId })
      }
    };
  };

  const getAddToCartDetails = (products, quantity = 1, listId = '') => {
    return {
      event: EVENT_ADD_TO_CART,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        items: getItems({ products, quantity, listId })
      }
    };
  };

  const getRemoveFromCartDetails = (products, quantity = 1, listId = '') => {
    return {
      event: EVENT_REMOVE_FROM_CART,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        items: getItems({ products, quantity, listId })
      }
    };
  };

  const getShowCartpageDetails = (cart) => {
    const products = cartGetters.getItems(cart);
    const totals = cartGetters.getTotals(cart);

    return {
      event: EVENT_SHOW_CARTPAGE,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        currency: currency.value,
        value: formatEventPrice(totals.total),
        items: getCartItems(products)
      }
    };
  };

  const getBeginCheckoutDetails = (cart) => {
    const products = cartGetters.getItems(cart);
    const total = cartGetters.getTotals(cart)?.total ?? 0;
    const couponCodeAppliedToCart = cartGetters.getAppliedCoupon(cart)?.code ?? '';
    const couponValueAppliedToCart = cartGetters.getDiscountAmount(cart) ?? '';
    const extraService = getExtraServices(cart);
    const items = getCartItems(products);
    const samples = getCartSamples(products);

    return {
      event: EVENT_BEGIN_CHECKOUT,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        currency: currency.value,
        value: formatEventPrice(total),
        items,
        sample_1: samples[0] || '',
        sample_2: samples[1] || '',
        extraService,
        coupon: couponCodeAppliedToCart,
        coupon_value: couponValueAppliedToCart
      }
    };
  };

  const getAddDeliveryAddressDetails = () => {
    return {
      event: EVENT_ADD_DELIVERY_ADDRESS,
      event_category: ECOMMERCE_EVENT
    };
  };

  const getAddShippingInfoDetails = (cart) => {
    const products = cartGetters.getItems(cart);
    const total = cartGetters.getTotals(cart)?.total ?? 0;
    const couponCodeAppliedToCart = cartGetters.getAppliedCoupon(cart)?.code ?? '';
    const couponValueAppliedToCart = cartGetters.getDiscountAmount(cart) ?? '';
    const extraService = getExtraServices(cart);
    const shippingMethod = cartGetters.getSelectedShippingMethod(cart)?.method_title ?? '';
    const items = getCartItems(products);
    const samples = getCartSamples(products);

    return {
      event: EVENT_ADD_SHIPPING_INFO,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        currency: currency.value,
        value: formatEventPrice(total),
        shippingMethod,
        items,
        sample_1: samples[0] || '',
        sample_2: samples[1] || '',
        extraService,
        coupon: couponCodeAppliedToCart,
        coupon_value: couponValueAppliedToCart
      }
    };
  };

  const getAddPaymentInfoDetails = (cart) => {
    const products = cartGetters.getItems(cart);
    const total = cartGetters.getTotals(cart)?.total ?? 0;
    const couponCodeAppliedToCart = cartGetters.getAppliedCoupon(cart)?.code ?? '';
    const couponValueAppliedToCart = cartGetters.getDiscountAmount(cart) ?? '';
    const extraService = getExtraServices(cart);
    const shippingMethod = cartGetters.getSelectedShippingMethod(cart)?.method_title ?? '';
    const paymentMethod = getPaymentMethod();
    const items = getCartItems(products);
    const samples = getCartSamples(products);

    return {
      event: EVENT_ADD_PAYMENT_INFO,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        currency: currency.value,
        value: formatEventPrice(total),
        shippingMethod,
        paymentMethod,
        items,
        sample_1: samples[0] || '',
        sample_2: samples[1] || '',
        extraService,
        coupon: couponCodeAppliedToCart,
        coupon_value: couponValueAppliedToCart
      }
    };
  };

  const getPurchaseDeliveryMethod = (shippingMethod: string): string => {
    const deliveryMethod = shippingMethod.split(' - ');

    return deliveryMethod.length > 1 ? deliveryMethod[1] : deliveryMethod[0];
  };

  const getPurchaseDetails = (
    products,
    totals,
    isGiftWrappingSet = '',
    isGiftMessageSet = '',
    appliedCouponCode = '',
    appliedCouponValue = '',
    shippingMethod = '',
    orderId = ''
  ) => {
    const extraService = getOrderExtraServices(isGiftWrappingSet, isGiftMessageSet);
    const paymentMethod = getPaymentMethod();
    const items = getOrderItems(products, 'success');
    const samples = getOrderSamples(products);

    return {
      event: EVENT_PURCHASE,
      event_category: ECOMMERCE_EVENT,
      ecommerce: {
        currency: currency.value,
        value: formatEventPrice(totals.grandTotal),
        shippingMethod: getPurchaseDeliveryMethod(shippingMethod),
        shipping: formatEventPrice(totals.shipping),
        paymentMethod,
        transaction_id: orderId,
        tax: formatEventPrice(totals.tax),
        items,
        sample_1: samples[0] || '',
        sample_2: samples[1] || '',
        extraService,
        coupon: appliedCouponCode,
        coupon_value: appliedCouponValue
      }
    };
  };

  return {
    EVENT_TIMEOUT,
    getPageviewDetails,
    getSelectItemDetails,
    getViewItemDetails,
    getViewItemListDetails,
    getAddToCartDetails,
    getRemoveFromCartDetails,
    getShowCartpageDetails,
    getBeginCheckoutDetails,
    getAddDeliveryAddressDetails,
    getAddShippingInfoDetails,
    getAddPaymentInfoDetails,
    getPurchaseDetails
  };
}
