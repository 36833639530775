import { useContext } from '@nuxtjs/composition-api';

import { urlRegExp } from '~/diptyqueTheme/helpers/regexes';

export function useLink() {
  const {
    app: { i18n }
  } = useContext();
  const code = i18n.localeProperties.code || i18n.defaultLocale;

  const isAbsolute = (link: string): boolean | undefined => (!link ? undefined : urlRegExp.test(link));
  const isRelative = (link: string): boolean | undefined => (!link ? undefined : !isAbsolute(link));
  const isInternal = (link: string): boolean | undefined => {
    const vsfStoreUrl = process.env.VSF_STORE_URL.split('//')[1];
    return !link ? undefined : link.includes(vsfStoreUrl.replace('www.', '')) || isRelative(link);
  };
  const isExternal = (link: string): boolean | undefined => (!link ? undefined : !isInternal(link));

  const isLocalised = (link: string): boolean => {
    if (!link) return false;
    const locales = i18n.locales.map((locale) => locale.code);
    return link.includes(`/${code}/`) || locales.some((cd) => link.includes(`/${cd}/`));
  };

  const getTargetVal = (link: string): string => {
    if (!link) return '';
    return isInternal(link) ? '' : '_blank';
  };

  const normalizeLink = (link: string, defaultLink = ''): string => {
    if (!link) return defaultLink;
    if (link === '##') return '';
    if (isAbsolute(link)) return link;
    if (link[0] !== '/') link = '/' + link;
    if (!isLocalised(link)) link = '/' + code + link;
    return link;
  };

  return {
    normalizeLink,
    isAbsolute,
    isRelative,
    isInternal,
    isExternal,
    isLocalised,
    getTargetVal
  };
}
