import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';

export const updateEngravingItemCommand = {
  execute: async (
    context: VsfContext,
    { currentCart, product, quantity, customizable_options, customQuery = { updateCartItems: 'updateCartItems' } }
  ) => {
    Logger.debug('[Magento]: Update engraved product on cart', {
      product,
      quantity,
      customizable_options,
      currentCart
    });

    const productOption = product.product.options ?? [];
    const engravingOption = productOption.find((option) => option.title.toLowerCase() === 'engraving');
    const optionId = engravingOption?.option_id ?? '';

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_id: product.id,
          customizable_options: [
            {
              id: optionId,
              value_string: customizable_options
            }
          ],
          quantity
        }
      ]
    };

    const { data, errors } = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

    Logger.debug('[Result]:', { data });

    if (errors?.length) {
      return errors;
    }

    return data.updateCartItems.cart as unknown as Cart;
  }
};
