import { defineStore } from 'pinia';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';

const useQuickSearch = defineStore('quick-search-store', {
  state: () => {
    return {
      searchTerm: lsGet('quick_search_term') ?? '',
      products: [],
      cmsPages: [],
      categories: [],
      trendingProducts: [],
      suggestions: [],
      lastSearches: [],
      quickSearchOpen: false
    };
  },
  actions: {
    setProducts(arr) {
      this.products = Array.isArray(arr) ? arr : [];
    },
    setCmsPages(arr) {
      this.cmsPages = Array.isArray(arr) ? arr : [];
    },
    setCategories(arr) {
      this.categories = Array.isArray(arr) ? arr : [];
    },
    setSuggestions(arr) {
      this.suggestions = Array.isArray(arr) ? arr : [];
    },
    setTrendingProducts(arr) {
      this.trendingProducts = Array.isArray(arr) ? arr : [];
    },
    setLastSearches(arr) {
      this.lastSearches = Array.isArray(arr) ? arr : [];
    },
    setQuickSearchOpen(bool) {
      this.quickSearchOpen = Boolean(bool);
    },
    setSearchTerm(searchTerm) {
      lsSet('quick_search_term', searchTerm);
      this.searchTerm = lsGet('quick_search_term');
    }
  }
});

export default useQuickSearch;
