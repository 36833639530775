import { computed, reactive } from '@nuxtjs/composition-api';

import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isSearchOpen: false,
  isCollectionSidebarOpen: false,
  isSubscriptionModalVisible: false,
  transparencyModalSrc: '',
  isContentLayerOpen: false,
  contentLayerTitle: '',
  contentLayerContent: '',
  sidebarActionType: '',
  isBundleLayerVisible: false,
  isProductZoomOpened: false,
  isEngravingInfoOpen: false,
  isRedirectModalOpen: false
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleRedirectModal = () => {
    state.isRedirectModalOpen = !state.isRedirectModalOpen;
  };
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleSearchState = () => {
    state.isSearchOpen = !state.isSearchOpen;
  };

  const toggleBundleLayer = () => {
    state.isBundleLayerVisible = !state.isBundleLayerVisible;
  };

  const toggleCartSidebar = (action_type: string | null) => {
    state.sidebarActionType = action_type;
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleCollectionSidebar = () => {
    state.isCollectionSidebarOpen = !state.isCollectionSidebarOpen;
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = state.isCollectionSidebarOpen ? 'hidden' : 'auto';
  };

  const hideCollectionSidebar = () => {
    if (state.isCollectionSidebarOpen) {
      toggleCollectionSidebar();
    }
  };

  const openEngravingInfo = () => {
    state.isEngravingInfoOpen = true;
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = 'hidden';
  };

  const hideEngravingInfo = () => {
    state.isEngravingInfoOpen = false;
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = 'auto';
  };

  const toggleSubscriptionModal = () => {
    state.isSubscriptionModalVisible = !state.isSubscriptionModalVisible;
    const actionType = state.isSubscriptionModalVisible ? 'add' : 'remove';
    document.body.classList[actionType]('no-scroll');
  };

  const setTransparencyModalSrc = (src: string) => {
    state.transparencyModalSrc = src;
  };

  const openContentLayer = (title: string, content: string) => {
    if (title && content) {
      state.contentLayerTitle = title;
      state.contentLayerContent = content;
      state.isContentLayerOpen = true;
    } else {
      console.error('Title and content are required for openContentLayer()');
    }
  };

  const closeContentLayer = () => {
    state.isContentLayerOpen = false;
  };

  const openProductZoom = () => {
    state.isProductZoomOpened = true;
  };

  const closeProductZoom = () => {
    state.isProductZoomOpened = false;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isCollectionSidebarOpen: computed(() => state.isCollectionSidebarOpen),
    isEngravingInfoOpen: computed(() => state.isEngravingInfoOpen),
    isSubscriptionModalVisible: computed(() => state.isSubscriptionModalVisible),
    transparencyModalSrc: computed(() => state.transparencyModalSrc),
    sidebarActionType: computed(() => state.sidebarActionType),
    isBundleLayerVisible: computed(() => state.isBundleLayerVisible),
    isProductZoomOpened: computed(() => state.isProductZoomOpened),
    isRedirectModalOpen: computed(() => state.isRedirectModalOpen),
    isSearchOpen: computed(() => state.isSearchOpen),
    toggleBundleLayer,
    toggleMobileMenu,
    toggleCollectionSidebar,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleSearchState,
    toggleSubscriptionModal,
    hideCollectionSidebar,
    openContentLayer,
    closeContentLayer,
    setTransparencyModalSrc,
    openProductZoom,
    closeProductZoom,
    toggleRedirectModal,
    isContentLayerOpen: computed(() => state.isContentLayerOpen),
    contentLayerTitle: computed(() => state.contentLayerTitle),
    contentLayerContent: computed(() => state.contentLayerContent),
    openEngravingInfo,
    hideEngravingInfo
  };
}

export default useUiState;
export * from './useUiState';
