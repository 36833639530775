import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{}');
export default integrationPlugin((plugin) => {
  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app
  });

  plugin.integration.configure('nosto', settings);
});
