import groupTypes from '~/diptyqueTheme/config/productGroupTypes';

type ProductsGroup = {
  type: string;
  groupTitle: string;
  sku: string[];
  nostoSlot: string;
};

type PDPUpsellProduct = {
  sku: string;
  [key: string]: any;
};

type Config = {
  pdpUpsellProducts?: PDPUpsellProduct[];
};

export function useProductGroup(config: Config) {
  const getTransformedData = async (sectionDataItems: [ProductsGroup] | null) => {
    let result = null;

    if (sectionDataItems) {
      result = sectionDataItems.filter(Boolean).map((item) => {
          if (item?.type === groupTypes.NOSTO) {
            item.sku = ['dummy']; //FIXME: For now we need to pass at least 1 sku (it wont be used), to make sure nosto carousels are actually rendered.
          }

          if (item?.type === groupTypes.UPSELLS) {
            item.sku = config?.pdpUpsellProducts?.map((p) => p.sku);
          }

          return item;
        });
    }

    return result;
  };

  return {
    getTransformedData
  };
}
