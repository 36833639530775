/** GraphQL query to fetch only stock status of items in the cart */
export default `
  query cart($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      items {
        id
        product {
          id
          stock_status
        }
      }
    }
  }
`;
