import crypto from 'crypto';
import { computed, readonly, ssrRef, useContext, useRoute } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import type {
  LRUCache,
  useLruCacheType
} from '@vaimo/vsf2_ext-lru-cache-driver';
import { useLruCache } from '@vaimo/vsf2_ext-lru-cache-driver';
import { Logger } from '~/helpers/logger';
import { setContentfulLocaleIfEmpty } from '~/integrations/contentful/src/helper/localeHelper';
import { UseContextReturn } from '~/types/core';
import { useContentfulGqlStore } from '~/integrations/contentful/src/stores/contetfulGql';
import { UseContentfulGqlError, UseContentfulGqlInterface, QueryResponse } from '~/integrations/contentful/src/composables/useContentfulGQL/types';

function useContentfulCache({ id, locale, rawPromise }: { id: string; locale: string; rawPromise: Promise<any> }) {
  const lruCacheContainers = <useLruCacheType>useLruCache();
  // max age in ms, set 5min
  // eslint-disable-next-line no-magic-numbers
  const ttl = 1000 * 60 * 5;
  const containerName = 'contentful-gql';

  const execute = (cacheKey, ...callArgs) => {
    cacheKey += '-' + locale + '-' + id;

    /**
     * Handle cached result.
     * @param cachedResult {Object}
     */
    const onCachedResult = (cachedResult) => {
      return lruCacheContainers.unproxify(cachedResult.result);
    };

    /**
     * When booleanCheckOnResult returned false then execute raw non-cached call and save to SSR side cache for 5min.
     * @param cache {LRUCache}
     */
    const onNonCachedResult = async (cache: LRUCache) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = await rawPromise(...callArgs);

      cache.set(
        cacheKey,
        {
          result
        },
        ttl
      );

      return result;
    };

    /**
     * Check for cached result, if contains required variables to use, if false, then onNonCachedResult() will be called.
     * @param cachedResult
     */
    const booleanCheckOnResult = (cachedResult) => cachedResult && cachedResult.result;

    return lruCacheContainers.execute(
      containerName,
      cacheKey,
      onCachedResult,
      onNonCachedResult,
      booleanCheckOnResult
    );
  };

  return { execute };
}

export const useContentfulGQL = (id: string): UseContentfulGqlInterface => {
  const contentfulGqlStore = useContentfulGqlStore();
  const context: UseContextReturn = useContext();
  const {
    app: { i18n }
  } = useContext();
  const queryResponse = sharedRef<QueryResponse>(null, `useContentfulGQL-queryResponse-${id}`);
  const customQueryResponse = sharedRef<QueryResponse>(null, `useContentfulGQL-customQueryResponse-${id}`);
  const loading = sharedRef<boolean>(false, `useContentfulGQL-loading-${id}`);
  const error = sharedRef<UseContentfulGqlError>(
    {
      query: null,
      graphqlQuery: null
    },
    `useContentfulGQL-error-${id}`
  );
  const router = useRoute();
  const { preview, spaceEnv } = router.value.query;
  const cache = useContentfulCache({ id, locale: i18n.localeProperties.contentfulLocale, rawPromise: context.$vsf.$contentful.api.graphqlQuery });
  /**
   * Function to sort object alphabetically
   */
  const sortAlphabetically = (obj) => {
    return Object.keys(obj)
      .sort()
      .reduce((memo, cur) => ((memo[cur] = obj[cur]), memo), {});
  };

  /**
   * Function to get data set it and cache it in state
   */
  const queryRaw = async (query: string, variables: object) => {
    variables = setContentfulLocaleIfEmpty(variables, context);
    if (['getMegaMenu', 'getMicroDataBunchById', 'getMicroDataBunchByKey'].includes(query)) {
      const stringifyVariables = JSON.stringify(sortAlphabetically({ ...variables }));
      const hash = crypto.createHash('md5').update(stringifyVariables).digest('hex');
      const cacheKey = query + '-' + hash;

      return cache.execute(cacheKey, query, { ...variables, preview, spaceEnv });
    }

    return context.$vsf.$contentful.api.graphqlQuery(query, { ...variables, preview, spaceEnv });
  };

  const getAndCacheData = async (query: string, variables: object) => {
    variables = setContentfulLocaleIfEmpty(variables, context);
    const stringifyVariables = JSON.stringify(sortAlphabetically({ ...variables }));
    const cachedItem = contentfulGqlStore.getCachedItem(query, stringifyVariables);
    if (cachedItem) {
      queryResponse.value = <QueryResponse>cachedItem;
    } else {
      queryResponse.value = await queryRaw(query, { ...variables, preview, spaceEnv });
      if (!contentfulGqlStore.cache[query]) {
        // @ts-ignore
        contentfulGqlStore.cache[query] = [];
        contentfulGqlStore.cache[query].push({
          variables: stringifyVariables,
          response: queryResponse.value
        });
      }
    }
  };

  /**
   * Make Custom graphQL query to Contentful
   */
  const query = async (query: string, variables: object = {}) => {
    Logger.debug(`useContentfulGQL/${id}/query`, query, variables);
    variables = setContentfulLocaleIfEmpty(variables, context);

    try {
      loading.value = true;
      await getAndCacheData(query, variables);
      error.value.query = null;
    } catch (err) {
      error.value.query = err;
      Logger.error(`useContentfulGQL/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  const customQuery = async (query: string, variables: object = {}) => {
    Logger.debug(`useContentfulGQL/${id}/customQuery`, query, variables);
    variables = setContentfulLocaleIfEmpty(variables, context);

    try {
      customQueryResponse.value = await context.$vsf.$contentful.api.graphqlCustomQuery(query, variables);
      error.value.graphqlQuery = null;
    } catch (err) {
      error.value.graphqlQuery = err;
      Logger.error(`useContentfulGQL/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  return {
    query,
    queryRaw,
    customQuery,
    queryResponse: computed(() => queryResponse.value?.data),
    customQueryResponse: computed(() => customQueryResponse.value),
    loading: readonly(computed(() => loading.value)),
    error: readonly(computed(() => error.value))
  };
};
