import type { Ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';

import type { EngravingConfig } from './types';
import type { UseEngravingErrors, UseEngravingInterface, UseEngravingParamsInput } from './useEngraving';

/**
 * Custom composable function for handling engraving-related operations.
 * It provides methods for fetching engraving configuration data and exposes
 * reactive variables for loading state, errors, and engraving configuration.
 *
 * @returns {UseEngravingInterface} An object containing methods and reactive variables
 * for managing engraving functionality.
 */
export function useEngraving(): UseEngravingInterface {
  const { app } = useContext();

  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseEngravingErrors> = ref({
    getProductEngravingConfig: null
  });
  const engravingConfig: Ref<EngravingConfig | null> = ref(null);

  const getProductEngravingConfig = async (params: UseEngravingParamsInput) => {
    Logger.debug('useEngraving/getProductEngravingConfig', params);
    try {
      loading.value = true;

      const { data } = await app.$vsf.$magento.api.getProductEngravingConfig({
        sku: params?.sku,
        store_code: params?.store_code
      });

      engravingConfig.value = data?.getProductEngravingConfig ?? null;

      error.value.getProductEngravingConfig = null;
    } catch (err) {
      error.value.getProductEngravingConfig = err;
      Logger.error('useEngraving/getProductEngravingConfig', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getProductEngravingConfig,
    loading,
    error,
    engravingConfig
  };
}

export * from './useEngraving';

export default useEngraving;
