import { computed, ref } from '@nuxtjs/composition-api';
import axios from 'axios';
import { Logger } from '~/helpers/logger';

import type { GetCountryCodeByIpResponse } from './types';

const userCountry = ref<string>();

export function useIPData() {
  const getCountryCodeByIP = async (): Promise<void> => {
    try {
      const { data }: GetCountryCodeByIpResponse = await axios.get('https://api.country.is/');

      if (data?.country) {
        userCountry.value = data.country;
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  return {
    getCountryCodeByIP,
    userCountry: computed(() => userCountry.value)
  };
}
