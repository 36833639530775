import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery } from '~/types/core';

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery = {
        removeItemFromCart: 'removeItemFromCart'
      }
    }
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart
    });

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_id: product.id
    };

    const { data } = await context.$magento.api.removeItemFromCart(removeItemParams, customQuery);

    Logger.debug('[Result]:', { data });

    // eslint-disable-next-line consistent-return
    return data.removeItemFromCart.cart as unknown as Cart;
  },

  mergeAfterRemoving: function (updatedCart, currentCart) {
    Logger.debug('useCart.mergeCartItems', updatedCart);

    const keys = Object.keys(updatedCart).filter((el) => el !== '__typename');
    if (keys?.length) {
      keys.forEach((key) => {
        if (key === 'items') {
          const items = updatedCart['items'];
          items?.forEach((updatedItem) => {
            updatedItem = currentCart.items?.find((item) => updatedItem.uid === item.uid);
          });
          currentCart.items = items;
        } else {
          currentCart[key] = updatedCart[key];
        }
      });
    }
    return currentCart;
  }
};
