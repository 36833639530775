import { computed, ref, useRoute } from '@nuxtjs/composition-api';

import { useApi } from '~/composables';
import {
  GetCategoryUrlByIdResponse,
  GetProductBySkuResponse,
  UseContentfulPreviewInterface
} from '~/diptyqueTheme/composable/useContentfulPreview/types';
import getCategoryUrlById from '~/diptyqueTheme/customQueries/magento/getCategoryUrlById';
import getProductUrlBySku from '~/diptyqueTheme/customQueries/magento/getProductUrlBySku';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

export function useContentfulPreview(): UseContentfulPreviewInterface {
  const route = useRoute();
  const { preview: previewIndex, cids: categoryIds, skus: productSKUs } = route.value.query;
  const { query } = useApi();

  const categoryStore = useCategoryStore();

  const categoryURL = ref('');
  const productURL = ref('');

  const getCategoryLink = async (): Promise<void> => {
    const idsArray = (categoryIds as string).split(',');
    try {
      const { data }: GetCategoryUrlByIdResponse = await query(getCategoryUrlById, {
        filters: { ids: { in: idsArray[+previewIndex - 1] } }
      });
      const categoryItems = data?.categories?.items || [];
      if (categoryItems.length > 0) {
        const { url_path } = categoryItems[0];
        categoryURL.value = `l/${url_path}.html`;
        categoryStore.setClearUrl(url_path);
      }
    } catch (e) {
      console.error('getCategoryLink error', e);
    }
  };

  const getProductLink = async (): Promise<void> => {
    if (!productSKUs) return;

    const skusArray = (productSKUs as string).split(',');
    try {
      const { data }: GetProductBySkuResponse = await query(getProductUrlBySku, {
        filter: { sku: { eq: skusArray[+previewIndex - 1] } }
      });
      const productItems = data?.products?.items || [];
      const productUrlRewrites = productItems[0]?.url_rewrites || [];
      if (productItems.length > 0 && productUrlRewrites.length > 0) {
        const { url } = productUrlRewrites[0];
        productURL.value = url;
      }
    } catch (e) {
      console.error('getProductLink error', e);
    }
  };

  const isPreviewMode = computed(() => !!route.value.query.preview);

  return {
    getCategoryLink,
    getProductLink,
    isPreviewMode,
    categoryURL,
    productURL
  };
}
