// // Note: this is the approach demonstrated at VSF2 documentary that brings us to 'window is undefined' error
// import { integrationPlugin } from '@vue-storefront/core';
//
// export default integrationPlugin(({ integration }) => {
//   integration.configure('contentful', { ...{}})
// });

// Note: this is the different approach of plugin's code to get rid of 'window is undefined' error
import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{}');
export default integrationPlugin((plugin) => {
  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app
  });

  plugin.integration.configure('contentful', settings);
});
