import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.placeOrder({ cart_id: cartId }, params?.customQuery ?? null);

    if (errors) {
      throw errors[0];
    }

    return data?.placeOrder ?? null;
  },
};
