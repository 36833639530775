import { ref, readonly } from '@nuxtjs/composition-api';
import { useNostoGQL } from '~/integrations/nosto/src/composables/useNostoGQL';
import type { CartItemInterface, CategoryTree } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useConfigStore } from '~/stores/config';
import { availableNostoTypes, responseNostoTypes, nostoCustomerData, nostoCartItem, nostoCart } from './types';
import { Logger } from '~/helpers/logger';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useUser } from '~/modules/customer/composables/useUser';

const recommendedProductsBySlot = ref({});

export function useNosto() {
  const cartStore = useCartStore();
  const configStore = useConfigStore();
  const currency = configStore.storeConfig.default_display_currency_code;
  const { updateSession, queryResponse } = useNostoGQL('nostoGql');
  const { getProductDetails } = useProduct();
  const { user } = useUser();
  const availableTypes: availableNostoTypes = {
    home: 'homeRecommendations',
    category: 'categoryRecommendations',
    product: 'productRecommendations',
    cart: 'cartViewed',
    vaimoKlevuSearch: 'forSearchPage',
    forNotFoundPage: 'forNotFoundPage'
  };
  const typeToResponse: responseNostoTypes = {
    homeRecommendations: 'forFrontPage',
    categoryRecommendations: 'forCategoryPage',
    productRecommendations: 'forProductPage',
    forSearchPage: 'forSearchPage',
    cartViewed: 'forCartPage',
    forNotFoundPage: 'forNotFoundPage'
  };

  const getVariables = async (request_type: string, target: string, options?: any) => {
    const result = {};
    result['target'] = target;
    if (cartStore.cart?.items) {
      result['cart'] = mapCartData(cartStore.cart.items);
    }
    if (user.value) {
      result['customer'] = mapCustomerData(user.value);
    }
    if (options) {
      //filter out all options that have null as their value
      const optionsWithValues = Object.fromEntries(
        Object.entries(options).filter(([key, value]) => value !== null)
      );
      //add options to result
      Object.assign(result, optionsWithValues);
    }
    return result;
  };

  const mapCartData = (items: Array<CartItemInterface>): nostoCart => {
    const result = {
      items: []
    };
    for (const item of items) {
      const nostoCartItem: nostoCartItem = {
        productId: item?.product?.id,
        skuId: item?.product?.sku,
        name: item?.product?.name,
        unitPrice: item?.product?.price_range?.minimum_price?.final_price?.value,
        priceCurrencyCode: item?.product?.price_range?.minimum_price?.final_price?.currency ?? currency,
        quantity: item?.quantity
      };
      result.items.push(nostoCartItem);
    }
    return result;
  };

  const mapCustomerData = (user): nostoCustomerData => {
    const result = {
      firstName: null,
      lastName: null,
      customerReference: null
    };
    result['firstName'] = user?.firstname;
    result['lastName'] = user?.lastname;
    result['customerReference'] = user?.nosto_customer_reference;
    return result;
  };

  const getProductIdsForSlotsFromResult = (result: any, requestType: string): { [key: string]: Array<string> } => {
    const resultSet = result?.updateSession?.pages?.[typeToResponse[requestType]];

    const slotProductIdMap = Object.fromEntries(
      resultSet.map((block: any) => {
        if (block?.primary && Array.isArray(block.primary)) {
          return [block.resultId, block.primary.map((p: any) => p.productId)];
        }
      })
      .filter((x:any) => x)
    );
    if (Object.keys(slotProductIdMap).length) {
      return slotProductIdMap;
    }
    throw new Error('Some error in response from Nosto or empty set of products');
  };

  // update Nosto session and store recommended Products that Nosto replies with
  const updateNostoSession = async (requestType: string, target: string, options?: any): Promise<void> => {
    try {
      const vars = await getVariables(requestType, target, options);

      recommendedProductsBySlot.value = {}

      await updateSession(requestType, vars);

      recommendedProductsBySlot.value = getProductIdsForSlotsFromResult(queryResponse.value, requestType);

    } catch (error) {
      Logger.debug(error);
    }
  };

  // simply update session, ignoring any recommendations that we get back
  const updateNostoSessionWithoutRecommendations = async (requestType: string, target: string, options?: any): Promise<void> => {
    try {
      const vars = await getVariables(requestType, target, options);

      await updateSession(requestType, vars);

    } catch (error) {
      Logger.debug(error);
    }
  };

  return {
    updateNostoSession,
    updateNostoSessionWithoutRecommendations,
    recommendationTypes: availableTypes,
    recommendedProductsBySlot: readonly(recommendedProductsBySlot)
  };
}
