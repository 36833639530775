import { ProductAttributeSortInput } from '~/modules/GraphQL/types';

export function createProductAttributeSortInput(sortData: string): ProductAttributeSortInput {
  const baseData = sortData.split(/_/gi);
  const nostoSortParamName = 'nosto';
  const nostoParamPartsCount = 3;
  if (baseData.length === nostoParamPartsCount && baseData[0] === nostoSortParamName) {
    baseData[0] = baseData[0] + '_' + baseData[1];
    baseData[1] = baseData[2];
    delete baseData[2];
  }
  return baseData.length > 0 && baseData[0] !== '' ? Object.fromEntries([baseData]) : {};
}
