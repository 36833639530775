import { useContext, useRoute } from '@nuxtjs/composition-api';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';

export function useStructuredData() {
    const { $config: { vsfStoreUrl } } = useContext()
    const route = useRoute();
    let storeUrl = vsfStoreUrl

    // removing duplicated '/' in breadcrumb link
    if (vsfStoreUrl.endsWith('/')) {
        storeUrl = vsfStoreUrl.slice(0, -1)
    }

    const getCategoryBreadcrumbsStructuredData = (breadcrumbs) => {
        if (!breadcrumbs) { return }

        let structuredData = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: []
        }

        if (breadcrumbs && breadcrumbs.length) {
            breadcrumbs.forEach((breadcrumb, index) => {
                structuredData.itemListElement.push({
                    '@type': 'ListItem',
                    position: index + 1,
                    name: breadcrumb.text,
                    item: `${storeUrl}${breadcrumb.link}`
                })
            });
        }

        return structuredData
    }

    const getProductStructuredData = (product) => {
        if (!product) { return }

        let structuredData = {
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: product.name || '',
            image: [],
            description: product.meta_description || '',
            sku: product.sku || '',
            brand: {
                '@type': 'Brand',
                name: product.brand || 'Diptyque Paris'
            },
            offers: {
                '@type': 'Offer',
                url: `${storeUrl}${route.value.fullPath}`,
                priceCurrency: product.price_range?.minimum_price?.final_price?.currency || 'EUR',
                price: getPrice(product).final || '',
                itemCondition: 'NewCondition',
                availability: ''
            }
        }

        if (product.media_gallery && product.media_gallery.length) {
            product.media_gallery.forEach(image => {
                structuredData.image.push(image.url)
            })
        }

        if (product.stock_status === 'IN_STOCK' && product.is_salable) {
            structuredData.offers.availability = 'InStock'
        } else {
            structuredData.offers.availability = 'OutOfStock'
        }

        return structuredData
    }

    return {
        getCategoryBreadcrumbsStructuredData,
        getProductStructuredData
    }
}
