import { computed, readonly, ssrRef, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import type { UseContentfulError, UseContentfulInterface } from '~/integrations/contentful/src/composables/useContentful/types';
import { useContentfulStore } from '~/integrations/contentful/src/stores/contentful';
import type { UseContextReturn } from '~/types/core';

export const useContentful = (id: string): UseContentfulInterface => {
  const context: UseContextReturn = useContext();
  const contentfulStore = useContentfulStore();
  const entry = ssrRef(null, `useContentful-entry-${id}`);
  const entries = ssrRef(null, `useContentful-entries-${id}`);
  const loading = ssrRef<boolean>(false, `useContentful-loading-${id}`);
  const error = ssrRef<UseContentfulError>(
    {
      call: null,
      search: null,
      getEntry: null,
      getEntries: null,
      graphqlQuery: null
    },
    `useContentful-error-${id}`
  );

  /**
   * Make Custom graphQL query to Contentful
   */
  const graphqlQuery = async (query: string, variables: object = {}, config: object = {}) => {
    console.log(`useContentful/${id}/graphqlQuery`, query, variables, config);

    try {
      loading.value = true;
      entry.value = await context.$vsf.$contentful.api.graphqlQuery(query, variables, config);
      error.value.graphqlQuery = null;
    } catch (error) {
      error.value.graphqlQuery = error;
      Logger.error(`useContentful/${id}/graphqlQuery`, error);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Get one Entry by ID
   */
  const getEntry = async (id: string, params: object = {}) => {
    console.log(`useContentful/${id}/getEntry`, id, params);

    try {
      loading.value = true;
      const cachedItem = contentfulStore.getCachedItem(id);
      if (cachedItem) {
        entry.value = cachedItem;
      } else {
        entry.value = await context.$vsf.$contentful.api.getEntry(id, params);
        contentfulStore.cache.push(entry.value);
      }
      error.value.getEntry = null;
    } catch (error) {
      error.value.getEntry = error;
      Logger.error(`useContentful/${id}/getEntry`, error);
    } finally {
      loading.value = false;
    }
  };

  const getEntries = async (params: object = {}) => {
    console.log(`useContentful/${id}/getEntries`, params);

    try {
      loading.value = true;
      entries.value = await context.$vsf.$contentful.api.getEntries(params);

      error.value.getEntries = null;
    } catch (error) {
      error.value.getEntries = error;
      Logger.error(`useContentful/${id}/getEntries`, error);
    } finally {
      loading.value = false;
    }
  };

  return {
    getEntry,
    getEntries,
    graphqlQuery,
    entry: computed(() => entry.value),
    entries: computed(() => entries.value),
    loading: readonly(computed(() => loading.value)),
    error: readonly(computed(() => error.value))
  };
};
