import type { VsfContext } from '~/composables/context';
import type { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { useCartStore } from '~/modules/checkout/stores/cart';
import type { Cart } from '~/modules/GraphQL/types';

export const loadCartCommand = {
  execute: async (
    context: VsfContext,
    params: ComposableFunctionArgs<{
      realCart?: boolean;
      forceReload?: boolean;
    }>
  ) => {
    const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const forceReload: boolean = params.forceReload;
    const createVirtualCart = () => null as Cart;

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      Logger.debug('[Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const { data, errors } = await context.$magento.api.cart(id);
      Logger.debug('[Result]:', { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      apiState.setCartId(data.cart.id);
      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (process.client) {
        const cart = JSON.parse(localStorage.getItem('cart'));
        if (shouldReturnCartFromStorage(cart)) {
          return cart;
        }
        if (cart?.store !== apiState.getStore()) {
          localStorage.removeItem('cart');
        }
      }
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    const shouldReturnCartFromStorage = function (cart): boolean {
      if (forceReload) {
        return false;
      }
      if (cart?.store === apiState.getStore() && apiState.getCartId() && apiState.getCartId()) {
        return true;
      }
      return false;
    };

    const getCurrentStoreCode = function () {
      const url = location.href;
      if (!url.includes(apiState.getStore())) {
        return url.split('/')[3];
      }
      return apiState.getStore();
    };
    const getStoreCartIds = function () {
      const user = apiState.getCustomerToken() ? 'user' : 'guest';
      // TODO: Refactoring of LocalStorage
      const storeCarts = JSON.parse(localStorage.getItem('store_cart'));
      if (storeCarts?._value && storeCarts._value[user]) {
        return storeCarts._value[user];
      } else if (storeCarts && storeCarts[user]) {
        return storeCarts[user];
      }
      return [];
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        const { data, errors } = await context.$magento.api.customerCart();
        Logger.debug('[Result]:', { data, errors });

        if (!data?.customerCart && errors?.length) {
          throw errors[0];
        }

        apiState.setCartId(data.customerCart.id);
        data.customerCart.items = data.customerCart.items.filter(Boolean);

        return data.customerCart as unknown as Cart;
      } catch {
        apiState.removeCustomerToken();
      }
    }

    try {
      const cartStore = useCartStore();
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = getStoreCartIds()[getCurrentStoreCode()] ?? cartStore?.cart.id;

      if (!cartId) {
        // Create empty cart if cartId isn't set yet
        return getCart(false);
      }

      return getCart(virtual, cartId);
    } catch {
      apiState.setCartId(undefined);

      return getCart(virtual);
    }
  }
};
