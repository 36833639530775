import type { Context, NuxtAppOptions } from '@nuxt/types';
import { LocaleObject } from '@nuxtjs/i18n';

const findLocaleBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>) =>
  locales.find((locale) => (typeof locale === 'object' ? locale.code : locale) === storeCode);

const findCurrencyBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>): string => {
  const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode) as LocaleObject | undefined;
  return match?.defaultCurrency;
};

/**
 * Prepare new cookie string based on app state.
 *
 * @param app {NuxtAppOptions}
 * @param newStoreCode {string}
 * @param currency {string}
 * @returns {string}
 */
const prepareNewCookieString = (app: NuxtAppOptions, newStoreCode: string, currency: string) => {
  const apiState = app.$vsf.$magento.config.state;
  const customerTokenCookie = apiState.getCustomerToken();
  const cartIdCookie = apiState.getCartId();
  const country = apiState.getCountry();

  let cookie = `vsf-store=${newStoreCode}; `;
  cookie += `vsf-locale=${newStoreCode}; `;

  if (currency) {
    cookie += `vsf-currency=${currency}; `;
  }

  if (country) {
    cookie += `vsf-country=${country}; `;
  }

  if (customerTokenCookie) {
    cookie += `vsf-customer=${customerTokenCookie}; `;
  }

  if (cartIdCookie) {
    cookie += `vsf-cart=${cartIdCookie} `;
  }

  return cookie;
};

export default ({ app, route }: Context) => {
  const fulfilled = async (request) => {
    const {
      $vsf: {
        $magento: {
          config: { state }
        }
      },
      i18n
    } = app;

    const currentStoreCode = state.getStore() ?? route.path.split('/')[1]; // localhost:3000/default
    const shouldSetDefaultLocale = !currentStoreCode || !findLocaleBasedOnMagentoStoreCode(currentStoreCode, i18n.locales);
    const i18nCurrentLocaleCode = i18n.locale;

    if (shouldSetDefaultLocale) {
      const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);

      state.setStore(i18nCurrentLocaleCode);
      state.setLocale(i18nCurrentLocaleCode);
      state.setCurrency(currency);
    }

    const shouldLocaleBeRefreshed = i18nCurrentLocaleCode !== state.getLocale();

    if (shouldLocaleBeRefreshed) {
      const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);

      state.setStore(i18nCurrentLocaleCode);
      state.setLocale(i18nCurrentLocaleCode);
      state.setCurrency(currency);
    }

    // on client side xhr error on setting "cookie"
    if (process.server) {
      // eslint-disable-next-line no-param-reassign
      request.headers.cookie = prepareNewCookieString(app, i18nCurrentLocaleCode, state.getCurrency());
    }

    return request;
  };

  const rejected = (error) => {
    console.error(['plugins/i18n: interceptor rejected', error]);

    return Promise.reject(error);
  };

  app.$vsf.$magento.client.interceptors.request.use(fulfilled, rejected);
  app.$vsf.$contentful.client.interceptors.request.use(fulfilled, rejected);
  app.$vsf.$vmoproxy && app.$vsf.$vmoproxy.client.interceptors.request.use(fulfilled, rejected);
  app.$vsf.$nosto.client.interceptors.request.use(fulfilled, rejected);
  app.$vsf.$adyen.client.interceptors.request.use(fulfilled, rejected);

  return true;
};
