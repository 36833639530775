import { useRoute, useRouter } from '@nuxtjs/composition-api'
// @ts-ignore
import { searchStore as useSearch } from '../../index'
import { FilterManager } from '@klevu/core'

interface KlevuFuilterOption {
    name: string;
    value: string;
    count: number;
    selected: boolean;
};

interface FilterFromUrl {
    key: string;
    values: string | (string | null)[] | [string | (string | null)[]];
    settings?: {
        singleSelect: boolean;
    };
};

const useUrlHelper = () => {
  const route = useRoute()
  const router = useRouter()
  const searchStore = useSearch()

  // list of query params that are not filters
  // but should be included in query
  const nonFilters = ['q', 'sort', 'page', 'itemsPerPage']

  const getQueryFromFilterManager = (manager: FilterManager) => {
    const filterQuery = {}

    // check each filter in Klevu filterManager for selected filters
    manager.options.forEach((filter) => {
      const selected: string[] = []

      // if filter option is selected, add to selected array
      filter.options.forEach((option: KlevuFuilterOption) => {
        if (option.selected) {
          selected.push(option.value)
        }
      })

      // if at least one option selected, add filter param to filterQuery
      if (selected.length) {
        filterQuery[filter.key] = selected
      }
    })

    return filterQuery
  }

  const getNonFilterQueryFromRoute = () => {
    const nonFilterQuery = {}

    // get nonFilter params from URL
    nonFilters.forEach((prop) => {
      if (route.value.query[prop]) {
        nonFilterQuery[prop] = route.value.query[prop]
      }
    })

    return nonFilterQuery
  }

  const getFitersFromUrl = () => {
    const currentQueryParams = Object.keys(route.value.query)
    const filters: FilterFromUrl[] = []

    if (currentQueryParams.length) {
      currentQueryParams.forEach((param) => {
        if (!nonFilters.includes(param)) {
          filters.push({
            key: param,
            settings: {
              singleSelect: false
            },
            values: Array.isArray(route.value.query[param]) ? route.value.query[param] : [route.value.query[param]]
          })
        }
      })
    }

    return filters
  }

  const applyQuery = (manager: FilterManager) => {
    const nonFilterQuery: { [index: string]: string|number } = getNonFilterQueryFromRoute()
    const filterQuery = getQueryFromFilterManager(manager)

    if (nonFilterQuery.page && nonFilterQuery.page !== searchStore.page) {
      nonFilterQuery.page = searchStore.page
    }

    router.push({
      query: {
        ...nonFilterQuery,
        ...filterQuery
      }
    })

    searchStore.toggleFilterSidebar()
  }

  return {
    getQueryFromFilterManager,
    getNonFilterQueryFromRoute,
    getFitersFromUrl,
    applyQuery
  }
}

export default useUrlHelper
