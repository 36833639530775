import { KlevuConfig } from '@klevu/core';
import { useContext } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

const useKlevuSearch = () => {
  const {
    app: {
      $klevuSearch,
      $vsf: { $magento: {
         config: contextConfig 
        }
      }
    }
  } = useContext();

  const getLocale = (): string => {
    return contextConfig.state.getLocale();
  }

  const getJsApiKey = (locale: string): string => {
    const jsApiKeyName = 'jsApiKey' + locale;
    const jsApiKeyNameDefault = 'jsApiKey';
    return $klevuSearch[jsApiKeyName] ?? $klevuSearch[jsApiKeyNameDefault];
  };

  const { config } = useConfig();

  let klevuApiKey = getJsApiKey(getLocale());

  if (config.value) {
    if (config.value.klevu_search_jsv2_api_key) {
      klevuApiKey = config.value.klevu_search_jsv2_api_key;
    }
  }

  let klevuApiUrl = $klevuSearch.searchUrl;

  if (config.value) {
    if (config.value.klevu_search_jsv2_api_url) {
      klevuApiUrl = 'https://' + config.value.klevu_search_jsv2_api_url + '/cs/v2/search';
    }
  }

  const init = () => {
    KlevuConfig.init({
      url: klevuApiUrl,
      apiKey: klevuApiKey
    });
  };

  return {
    init
  };
};

export default useKlevuSearch;
