/**
 * Composable to get main data for section
 * How to use:
 * - Import composable in component
 * - Add in setup function
 * - Use it like this - <VaimoHeading v-bind="headingData" />
 **/
import { HeadingInterface, TruncatedTextInterface, BlockSettingsInterface } from './types';
import { reactive, computed } from '@nuxtjs/composition-api';
export function useSection(sectionProps: { [key: string]: any }) {
  const headingData = computed<HeadingInterface | object>(() => {
    if (sectionProps?.heading) {
      return {
        heading: sectionProps?.heading?.heading,
        headingLevel: sectionProps?.heading?.headingTag,
        headingStyle: sectionProps?.heading?.headingStyle,
        subHeading: sectionProps?.heading?.subHeading,
        subHeadingStyle: sectionProps?.heading?.subHeadingStyle,
        subHeadingPosition: sectionProps?.heading?.subHeadingPosition,
        subHeadingLevel: sectionProps?.heading?.subHeadingLevel
      };
    }
    return {};
  });

  const truncatedTextData: TruncatedTextInterface = reactive({
    teaser: sectionProps?.text?.teaser,
    text: sectionProps?.text?.text || '',
    characters: sectionProps?.text?.truncateByChars,
    lines: sectionProps?.text?.truncateByLines,
    truncate: sectionProps?.text?.type
  });

  const blockSettings: BlockSettingsInterface = reactive({
    alignContent: {
      mobile: sectionProps?.mobileBlockSettings?.alignContent,
      desktop: sectionProps?.desktopBlockSettings?.alignContent
    },
    mbElements: {
      mobile: sectionProps?.mobileBlockSettings?.mbElements,
      desktop: sectionProps?.desktopBlockSettings?.mbElements
    }
  });

  const getContentAlignClasses = computed(() => {
    const list = [];
    const classesMap = {
      mobile: {
        center: 'text-center mx-auto',
        start: 'text-start',
        end: 'text-end'
      },
      desktop: {
        center: 'desktop:text-center desktop:mx-auto',
        start: 'desktop:text-start desktop:ml-0',
        end: 'desktop:text-end desktop:mr-0'
      }
    };
    const mobile = blockSettings?.alignContent?.mobile;
    const desktop = blockSettings?.alignContent?.desktop;
    if (mobile) list.push(classesMap.mobile[mobile]);
    if (desktop && desktop !== mobile) list.push(classesMap.desktop[desktop]);
    return list;
  });

  const getBlockElementClasses = computed(() => {
    const list = ['block__element'];
    const classesMap = {
      mobile: {
        xs: 'mb-xs',
        sm: 'mb-sm',
        base: 'mb-base',
        md: 'mb-md',
        md2: 'mb-2md',
        lg: 'mb-lg'
      },
      desktop: {
        xs: 'desktop:mb-xs',
        sm: 'desktop:mb-sm',
        base: 'desktop:mb-base',
        md: 'desktop:mb-md',
        md2: 'desktop:mb-2md',
        lg: 'desktop:mb-lg'
      }
    };
    const mobile = blockSettings?.mbElements?.mobile;
    const desktop = blockSettings?.mbElements?.desktop;
    if (mobile) list.push(classesMap.mobile[mobile]);
    if (desktop && desktop !== mobile) list.push(classesMap.desktop[desktop]);
    return list;
  });

  return {
    headingData,
    truncatedTextData,
    blockSettings,
    getContentAlignClasses,
    getBlockElementClasses
  };
}

export default useSection;
