import { computed, Ref } from '@nuxtjs/composition-api';

interface PayloadUnit {
  [key: string]: string;
}

const defaultCtfMetaTagFields: PayloadUnit = { title: 'title', description: 'description', keywords: 'keywords' };

export function useTransformedMeta() {
  const transformedMeta = {
    title: null,
    meta: []
  };

  const transformMeta = (rawMetadata: Ref<object>, ctfMetaTagFields = defaultCtfMetaTagFields) => {
    for (const metaTag in ctfMetaTagFields) {
      const ctfFieldId = ctfMetaTagFields[metaTag];
      const metaTagValue = rawMetadata.value?.[ctfFieldId];

      if (!metaTagValue) {
        continue;
      }

      if (metaTag === 'title') {
        transformedMeta.title = metaTagValue || '';
        continue;
      }

      transformedMeta.meta.push({
        hid: metaTag,
        name: metaTag,
        content: metaTagValue || ''
      });
    }

    return transformedMeta;
  };

  return {
    transformMeta
  };
}
