if (process.client && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';

  window.addEventListener('beforeunload', () => {
    window.history.scrollRestoration = 'auto';
  });
}

export default function scrollBehavior(to, from, savedPosition) {
  return new Promise((resolve) => {
    const renderTimeout = 1000;
    const waitForRender = () => {
      window.$nuxt.$nextTick(() => {
        if (document.readyState === 'complete') {
          requestAnimationFrame(() => {
            setTimeout(() => {
              if (savedPosition) {
                document.documentElement.style.scrollBehavior = 'auto';
                window.scrollTo(savedPosition.x, savedPosition.y);
                document.documentElement.style.scrollBehavior = 'smooth';
                resolve(savedPosition);
              } else if (to.hash) {
                resolve({ selector: to.hash });
              } else {
                resolve({ x: 0, y: 0 });
              }
            }, renderTimeout);
          });
        } else {
          waitForRender();
        }
      });
    };

    window.$nuxt.$router.onReady(waitForRender);
  });
}
