import { defineStore } from 'pinia';

interface ContentfulStore {
  cache: Array<{
    [key: string]: {
      [key: string]: any
    }
  }>
}

export const useContentfulStore = defineStore('contentful', {
  state: (): ContentfulStore => ({
    cache: []
  }),
  getters: {
    getCachedItem: state => (id: string): object => {
      return state.cache.find(item => item.sys.id === id)
    }
  }
})

