import { defineStore } from 'pinia';
import type { FreeShippingThresholdInfo } from '~/modules/GraphQL/types';

interface ProductState {
  isEngravingInfoOpen: boolean;
  isEngravingInfoProcessed: boolean;
  freeShippingInfo: FreeShippingThresholdInfo | null;
}

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    isEngravingInfoOpen: false,
    isEngravingInfoProcessed: false,
    freeShippingInfo: null
  }),
  actions: {
    setFreeShippingInfo(info: FreeShippingThresholdInfo | null) {
      this.freeShippingInfo = info;
    }
  }
});
