import { computed, readonly, ssrRef, useContext } from '@nuxtjs/composition-api';
import { UseContextReturn } from '~/types/core';
import { useNostoSessionStore } from '~/integrations/nosto/src/stores/session';
import { UseNostoGqlError, UseNostoGqlInterface } from '~/integrations/nosto/src/composables/useNostoGQL/types';
import { Logger } from '~/helpers/logger';

export const useNostoGQL = (id: string): UseNostoGqlInterface => {
  const nostoSessionStore = useNostoSessionStore();
  const context: UseContextReturn = useContext();
  const queryResponse = ssrRef(null, `useNostoGQL-queryResponse-${id}`);
  const loading = ssrRef<boolean>(false, `useNostoGQL-loading-${id}`);
  const error = ssrRef<UseNostoGqlError>(
    {
      query: null,
      graphqlQuery: null
    },
    `useNostoGQL-error-${id}`
  );
  /**
   * in minutes
   */
  const expirationPeriodForSession = 30;

  const getLocale = (): string => {
    return context.$vsf.$magento.config.state.getLocale();
  };

  const getNostoSessionId = async (): Promise<string> => {
    if (!nostoSessionStore.getSessionId(getLocale()) || isSessionExpired()) {
      //todo: put current url as a variable instead of '' string
      const newSessionResult = await context.$vsf.$nosto.api.newSession({ referer: '' }, getLocale());
      const newSession = newSessionResult?.data?.newSession;
      if (newSession && typeof newSession === 'string') {
        nostoSessionStore.setSessionId(newSession, getLocale());
      } else {
        throw new Error('Error with starting session for Nosto');
      }
    }
    return nostoSessionStore.getSessionId(getLocale());
  };

  const isSessionExpired = (): boolean => {
    return checkDiffInMinutes(new Date().getTime(), nostoSessionStore.getSessionCreatedAt(getLocale())) >= expirationPeriodForSession;
  };

  const checkDiffInMinutes = (dateNow: number, dateCreatedAt: string): number => {
    // eslint-disable-next-line no-magic-numbers
    let differenceValue = (dateNow - Number(dateCreatedAt)) / 1000;
    // eslint-disable-next-line no-magic-numbers
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
  };

  const updateSession = async (query: string, variables: object = {}) => {
    try {
      loading.value = true;
      const nostoSessionId = await getNostoSessionId();
      queryResponse.value = await context.$vsf.$nosto.api.updateSession(query, { id: nostoSessionId, ...variables }, getLocale());
      error.value.query = null;
    } catch (err) {
      error.value.query = err;
      Logger.error(`useNostoGQL/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  return {
    updateSession,
    queryResponse: computed(() => queryResponse.value?.data),
    loading: readonly(computed(() => loading.value)),
    error: readonly(computed(() => error.value))
  };
};
