import { computed, useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { useUrlResolver } from '~/composables';
import { Logger } from '~/helpers/logger';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { Products } from '~/modules/GraphQL/types';

import { createProductAttributeFilterInput } from './input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from './input/createProductAttributeSortInput';
import type { FacetSearchParams, UseCustomFacetInterface } from './useFacet';

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet(id?: string): UseCustomFacetInterface {
  const ssrKey = id || 'vmo-useFacet';
  const loading = sharedRef(false, `vmo-useFacet/${ssrKey}-loading-${id}`);
  const alternativeLoading = sharedRef(false, `vmo-useFacet/${ssrKey}-alternativeLoading-${id}`);
  const result = sharedRef({ data: null, input: null, lastInput: null }, `${ssrKey}-facets`);
  const error = sharedRef(
    {
      search: null
    },
    `vmo-useFacet/${ssrKey}-error-${id}`
  );

  const defaultItemsPerPage = 20;
  const context = useContext();
  const { routeData } = useUrlResolver();
  const { activeCategory } = useTraverseCategory();
  const nostoSortParam = 'nosto_personalized_ASC';

  const searchWithApi = async (params?: FacetSearchParams) => {
    const activeCategoryID = (params.category_uid || activeCategory.value?.id || routeData.value?.id) ?? '';
    const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

    const isPreview: boolean = context.route.value.query.preview === '1';
    const productSearchParams: GetProductSearchParams = {
      pageSize,
      search: params.term ? params.term : '',
      filter: createProductAttributeFilterInput(params, activeCategoryID),
      sort: createProductAttributeSortInput(params.sort || nostoSortParam),
      currentPage: params.page,
      is_preview: isPreview
    };

    if (isPreview) {
      delete productSearchParams.search;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore "customGetFacetData" dynamically added by custom-middleware
    const { data } = await context.app.$vsf.$magento.api.customGetFacetData<{ products: Products }>(productSearchParams);
    const products = data?.products ?? null;

    return {
      items: products?.items ?? [],
      aggregations: products?.aggregations ?? [],
      total: products?.total_count,
      availableSortingOptions: sortingOptions,
      perPageOptions,
      itemsPerPage: pageSize
    };
  };

  const searchWithoutReactive = async (params?: FacetSearchParams) => {
    try {
      alternativeLoading.value = true;
      const productsData = await searchWithApi(params);
      Logger.debug('[Result]:', { productsData });
      error.value.search = null;

      return {
        input: params,
        data: productsData,
        lastInput: params
      };
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      alternativeLoading.value = false;
    }

    return null;
  };

  const search = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;

    try {
      loading.value = true;
      const productsData = await searchWithApi(params);
      Logger.debug('[Result]:', { productsData });

      result.value = {
        input: params,
        data: productsData,
        lastInput: params
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    searchWithoutReactive,
    result: computed(() => result.value),
    loading: computed(() => loading.value),
    alternativeLoading: computed(() => alternativeLoading.value),
    error: computed(() => error.value)
  };
}
