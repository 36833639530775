import { defineStore } from 'pinia';
import type { AvailableShippingMethod } from '~/modules/GraphQL/types';

interface useAvailableShippingMethodsInterface {
  shippingMethods: AvailableShippingMethod[] | [];
  isPending: boolean;
  isBillingPending: boolean;
}

export const useAvailableShippingMethodsStore = defineStore('availableShippingMethods', {
  state: (): useAvailableShippingMethodsInterface => ({
    shippingMethods: [],
    isPending: false,
    isBillingPending: false
  }),

  actions: {
    setShippingMethods(shippingMethods: AvailableShippingMethod[] | []): void {
      this.shippingMethods = shippingMethods;
    }
  }
});
