import type { ApolloQueryResult } from '@apollo/client/core';

import type { VsfContext } from '~/composables/context';
import type { ComposableFunctionArgs } from '~/composables/types';
import cartStockStatus from '~/diptyqueTheme/customQueries/magento/cartStockStatus';
import customerCartStockStatus from '~/diptyqueTheme/customQueries/magento/customerCartStockStatus';
import { Logger } from '~/helpers/logger';
import type { CartStockStatus } from '~/modules/GraphQL/types';

interface CartResponse {
  cart: CartStockStatus | null;
  customerCart: CartStockStatus | null;
}

export const loadCartStockStatusCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{ cartId: string }>) => {
    const { cartId } = params;
    const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: Loading Cart');
    const customerToken = apiState.getCustomerToken();

    if (!cartId) {
      throw new Error('Cart ID is required');
    }

    Logger.debug('[Magento Storefront]: Loading Cart ID->', cartId);

    const { data, errors } = (await context.$magento.api.customQuery({
      query: cartStockStatus,
      queryVariables: { cartId }
    })) as ApolloQueryResult<CartResponse>;
    Logger.debug('[Result]:', { data });

    if (!data?.cart && errors?.length) {
      throw errors[0];
    }

    if (customerToken) {
      try {
        const { data, errors } = (await context.$magento.api.customQuery({
          query: customerCartStockStatus
        })) as ApolloQueryResult<CartResponse>;
        Logger.debug('[Result]:', { data, errors });

        if (!data?.customerCart && errors?.length) {
          throw errors[0];
        }

        apiState.setCartId(data.customerCart.id);
        data.customerCart.items = data.customerCart.items.filter(Boolean);

        return data.customerCart as unknown as CartStockStatus;
      } catch {
        apiState.removeCustomerToken();
      }
    }

    return data.cart as CartStockStatus;
  }
};
