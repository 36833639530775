import { defineStore } from 'pinia';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';

interface useNostoSessionInterface {
  sessionId: string | null;
  createdAt: string | null;
}

const NOSTO_SESSION_NAME = 'nostoSession';
const NOSTO_SESSION_CREATED_AT_KEY = 'nostoSessionCreatedAt';

export const useNostoSessionStore = defineStore(NOSTO_SESSION_NAME, {
  state: (): useNostoSessionInterface => ({
    sessionId: null,
    createdAt: null
  }),

  actions: {
    setSessionId(sessionId: string, locale: string): void {
      if (typeof localStorage !== 'undefined') {
        lsSet(NOSTO_SESSION_NAME + '_' + locale, sessionId);
        lsSet(NOSTO_SESSION_CREATED_AT_KEY + '_' + locale, new Date().getTime().toString());
        this.sessionId = lsGet(NOSTO_SESSION_NAME + '_' + locale);
        this.createdAt = lsGet(NOSTO_SESSION_CREATED_AT_KEY + '_' + locale);
      } else {
        this.sessionId = sessionId;
        this.createdAt = new Date().getTime().toString();
      }
    },
    getSessionId(locale: string): string | null {
      if (typeof localStorage !== 'undefined') {
        this.sessionId = lsGet(NOSTO_SESSION_NAME + '_' + locale);
      }
      return this.sessionId;
    },
    getSessionCreatedAt(locale: string): string | null {
      if (typeof localStorage !== 'undefined') {
        this.createdAt = lsGet(NOSTO_SESSION_CREATED_AT_KEY + '_' + locale);
      }
      return this.createdAt;
    }
  }
});
